<template>
  <div class="echartItemSingle" v-if="isShow">
    <div class="echartTitle">运行距离</div>
    <div id="runDistance" class="echartBox"></div>
  </div>
</template>

<script>
import * as echarts from "echarts";

export default {
  props: {
    data: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },

  data() {
    return {
      isShow: false,
      max: ""
    };
  },

  watch: {
    data: {
      handler(value) {
        const result = value.some(item => item.value !== 0);
        if (value.length > 0 && result) {
          this.isShow = true;
          this.max = Math.max(...value.map(item => item.value));
          this.init();
        } else {
          this.isShow = false;
        }
      }
    }
  },

  methods: {
    init() {
      this.$nextTick(() => {
        const myChart = this.$echarts.init(document.getElementById("runDistance"));
        const option = {
          title: {
            subtext: "单位：千米",
            left: 7
          },
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow"
            },
            formatter: "{b}<br />{c}千米"
          },
          grid: {
            left: "30",
            right: "24",
            bottom: "0%",
            top: "15%",
            containLabel: true
          },
          xAxis: {
            type: "category",
            data: this.data.map(item => item.name),
            axisTick: {
              show: false
            },
            axisLine: {
              show: false
            },
          },
          yAxis: {
            type: "value",
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            }
          },
          series: [
            {
              type: "bar",
              barWidth: 10,
              data: this.data.map(item => ({
                value: item.value,
                name: item.name,
                itemStyle: {
                  color: item.value == this.max ? new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    { offset: 0, color: "#0747FD" },
                    { offset: 1, color: "#B4C7FF" }
                  ]) : "#B4C7FF"
                }
              }))
            }
          ]
        };
        myChart.setOption(option);
      });
    }
  }
};
</script>